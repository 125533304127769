<template>
  <div>
    <b-modal centered id="tablePerson" title="Details" size="md" hide-footer>
      <b-table v-if="tablePerson.length > 0" striped responsive :fields="columnsPckage"
               :items="tablePerson" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
        <template v-slot:cell(sales_name)="data1">
          <p class="holdSelection m-0">{{data1.item.sales_target != null ?
              data1.item.sales_target.sales.name:
              'From Branch'}}</p>
        </template>
      </b-table>
      <p v-else class="text-black text-center"> This is Guest User</p>
    </b-modal>
    <b-row>
      <!-- start filter -->
      <b-container fluid class="filter">
        {{ getDefaultProject }}
        <div class="iq-card p-1">
          <div class="row">
            <div class="col-md-3 my-1">
              <select class="form-control" v-model="filter.type" @change="getAllMembers()">
                <option selected disabled value="">select Type...</option>
                <option value="active">Active</option>
                <option value="unactive">not start</option>
                <option value="expired">Expired</option>
              </select>
            </div>
            <div class="col-md-3 my-1">
              <select class="form-control"  v-model="filter.project" @change="getAllMembers()">
                <option selected disabled value="">select Project...</option>
                <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
                  <span>{{i.en_title}}</span>
                </option>
              </select>
            </div>
            <div class="col-md-1 d-flex justify-content-center align-items-center" v-if="filter.name || filter.type
            || filter.project|| filter.expired">
              <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
            </div>
          </div>
        </div>
      </b-container>
      <!-- end filter -->

      <!--start content-->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between w-100">
              <h4 class="card-title">Membership status</h4>
              <p class="ml-4 text-black font-weight-bold">( {{totalMember}} )</p>
            </div>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else sort-icon-left primary-key="code" striped responsive :fields="columns" :items="allMembers"
                     class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(name)="data">
                <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.id }}"><b-badge variant="primary">{{ data.item.name }}</b-badge></router-link>
              </template>
              <template v-slot:cell(type)="data1">
                <span v-if="data1.item.type == 'guest'" class="text-black">Guest</span>
                <span v-if="data1.item.type == 'member'" class="text-success">Member</span>
                <span v-if="data1.item.type == 'expired'" class="text-danger">Expired</span>
              </template>
<!--              <template v-slot:cell(available)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <span v-if="i.available" class="text-success"><i class="las la-check-circle"></i></span>
                    <span v-else class="text-danger"><i class="las la-times-circle"></i></span>
                  </li>
                </ul>
              </template>-->
              <template v-slot:cell(start)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <p>{{i.start}}</p>
                  </li>
                </ul>
              </template>
              <template v-slot:cell(expire)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <p>{{i.start}}</p>
                  </li>
                </ul>
              </template>
              <template v-slot:cell(package_title)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <span>{{i.package_title}}</span>
                  </li>
                </ul>
              </template>
              <template v-slot:cell(paid)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <span>{{i.paid}}</span>
                  </li>
                </ul>
              </template>
              <template v-slot:cell(remaining)="data">
                <ul class="m-0 p-0">
                  <li v-for="(i, key) in data.item.member_package" :key="key">
                    <span>{{i.remaining}}</span>
                  </li>
                </ul>
              </template>
            </b-table>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllMembers"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <!--end content-->

    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { core } from '../../../../config/pluginInit'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import services from '../../services/reports'
export default {
  name: 'allMemberReports',

  data () {
    return {
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      projects: [],
      filter: {
        name: '',
        type: '',
        project: '',
        expired: 0
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      totalMember: '',
      loadTable: true,
      columns: [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Package Title', key: 'package_title', class: 'text-left', sortable: true },
        { label: 'Start', key: 'start', class: 'text-left', sortable: true },
        { label: 'Expire', key: 'expire', class: 'text-left', sortable: true },
        { label: 'Paid', key: 'paid', class: 'text-left', sortable: true },
        { label: 'Remaining', key: 'remaining', class: 'text-left', sortable: true }
      ],
      allMembers: [],
      tablePerson: []
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    showTablePackageDetails (table) {
      this.$bvModal.show('tablePerson')
      this.tablePerson = table
    },
    changeMemberStatus (id) {
      this.$store.dispatch('changeMemberStatus', id).then(res => {
        core.showSnackbar('success', res.data.message)
      }).catch(err => {
        if (err.response.data.errors) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      })
    },
    resetFilter () {
      this.filter = {
        name: '',
        type: '',
        project: '',
        expired: 0
      }
      this.getAllMembers()
    },
    getAllMembers () {
      this.loadTable = true
      services.getAllMembersNotStart({
        pageNumber: this.pagination.currentPage,
        type: this.filter.type ? this.filter.type : '',
        projectId: this.filter.project ? this.filter.project : JSON.parse(localStorage.getItem('default_project')).id
      }).then(res => {
        this.allMembers = res.data.data.member.data
        this.pagination.currentPage = res.data.data.member.current_page
        this.pagination.per_page = res.data.data.member.per_page
        this.pagination.total = res.data.data.member.total
        this.totalMember = res.data.data.count
      }).finally(() => {
        this.loadTable = false
      })
    }
  },
  components: {
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllMembers()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
